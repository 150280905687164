import { $, $$ } from "@utils/dom";
import jQuery from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesloaded";
import { instance } from "@ui/site-scroll";
import { introLogo } from "@ui/animations";
const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.name = "loader";
  }

  beforeOnce({ next }) {
    return new Promise(resolve => {
      this._imgLoader = new ImagesLoaded(next.container, resolve);
    });
  }

  once() {
    return new Promise(resolve => {
      const tl = gsap.timeline();
      let count = 0;
      const CTCONTAINER = jQuery(".site-loader");

      tl.to(CTCONTAINER, {
        function() {
          instance.destroy();
          jQuery(".is-inview").removeClass("is-inview");
        },
      });

      introLogo();

      setTimeout(function () {
        tl.to(
          CTCONTAINER,
          {
            y: "-100%",
            duration: 1.5,
            autoAlpha: 1,
            ease: "power4.inOut",
            function() {
              setTimeout(function () {
                instance.init();
              }, 200);
            },
          },
          "<",
        );
        tl.to("#intro-logo", {
          onStart: () => {
            resolve();
          },
          onComplete: () => {},
        });
      }, 3300);
    });
  }
}

export default SiteLoader;
