import {
  slideSolution,
  slideInsights,
  slideClients,
  slideTeam,
  slideCulture,
  slideNews,
} from "@modules/slide";

import { featuresIcons } from "@ui/animations";
import textSplit from "@modules/text-split";
import { tabs, accordion, floatLabels } from "@modules/tricks";

export default [
  {
    namespace: "home",
    beforeEnter() {
      textSplit();
      slideSolution();
      featuresIcons();
      tabs();
      slideInsights();
      slideClients();
      slideTeam();
      slideCulture();
      accordion();
      slideNews();
      floatLabels();
    },
  },
];
