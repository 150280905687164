import Swiper, { Autoplay, Navigation, Thumbs } from "swiper";

function slideSolution() {
  new Swiper("#solution .swiper", {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: "#solution .next",
      prevEl: "#solution .prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  });
}

function slideInsights() {
  var swiperThumbs = new Swiper("#insights .swiper.thumbs", {
    slidesPerView: 2,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
    },
  });

  new Swiper("#insights .swiper.main", {
    modules: [Thumbs],
    loop: true,
    thumbs: {
      swiper: swiperThumbs,
    },
  });
}

function slideClients() {
  new Swiper("#clients .swiper", {
    modules: [Autoplay],
    speed: 3000,
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
  });
}

function slideTeam() {
  new Swiper("#team .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: "#team .next",
      prevEl: "#team .prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  });
}

function slideCulture() {
  var swiperThumbs = new Swiper("#culture .swiper.thumbs", {
    slidesPerView: 2,
    watchSlidesProgress: true,

    breakpoints: {
      768: {
        slidesPerView: 6,
      },
    },
  });

  new Swiper("#culture .swiper.main", {
    modules: [Autoplay, Thumbs],
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    thumbs: {
      swiper: swiperThumbs,
    },
  });
}

function slideNews() {
  new Swiper("#news .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: "#news .next",
      prevEl: "#news .prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  });
}

export {
  slideSolution,
  slideInsights,
  slideClients,
  slideTeam,
  slideCulture,
  slideNews,
};
