import $ from "jquery";
import { instance } from "@ui/site-scroll";

function toggleNav() {
  $(".navbar .toggle").on("click", function () {
    $("body").toggleClass("menu-open");
  });

  $(".navbar .menu a").on("click", function () {
    $("body").removeClass("menu-open");
  });
}

function tabs() {
  // Show the first tab and hide the rest
  $("#tabs-nav li:first-child").addClass("active");
  $(".tab-content").hide();
  $(".tab-content:first").show();
  $(".tab-content:first").addClass("active");

  // Click function
  $("#tabs-nav li").click(function () {
    $("#tabs-nav li").removeClass("active");
    $(this).addClass("active");
    $(".tab-content").fadeOut();

    var activeTab = $(this).find("a").attr("href");
    $(activeTab).fadeIn("slow");
    return false;
  });
}

function accordion() {
  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

function floatLabels() {
  $(".wpcf7-form-control:not(.wpcf7-submit)")
    .focus(function () {
      $(this).parent().parent().addClass("active");
    })
    .blur(function () {
      var cval = $(this).val();
      if (cval.length < 1) {
        $(this).parent().parent().removeClass("active");
      }
    });
}

export { toggleNav, tabs, accordion, floatLabels };
