import lottie from "lottie-web";

function featuresIcons() {
  lottie.loadAnimation({
    container: document.getElementById("blockchain"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/blockchain.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("collaboration"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/collaboration.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("community"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/community.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("decentralised"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/decentralised.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("industry"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/industry.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("infrastructure"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/infrastructure.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("internet"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/internet.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("sovereignty"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/sovereignty.json",
  });
}

function introLogo() {
  lottie.loadAnimation({
    container: document.getElementById("intro-logo"),
    renderer: "svg",
    autplay: true,
    path: "/wp-content/themes/impactility/dist/data/intro-logo.json",
  });
}

export { introLogo, featuresIcons };
